export function CashflowEntry(src) {
  this.id = src.id
  this.target_cashflow_id = src.target_cashflow_id
  this.name = src.name
  this.amount = parseInt(src.amount)
  this.entry_type = src.entry_type
  this.entry_at = stringToDate(src.entry_at)
  this.credit_amount = parseInt(src.credit_amount)
  this.debit_amount = parseInt(src.debit_amount)
  this.running_balance = parseInt(src.running_balance)
  this.cleared = src.cleared
  this.recurrence = src.recurrence
  this.created_at = new Date(src.created_at)
  this.updated_at = new Date(src.updated_at)
}

export function CashflowItem(src) {
  this.id = src.id
  this.target_cashflow_id = src.target_cashflow_id
  this.name = src.name
  this.amount = parseInt(src.amount)
  this.item_type = src.item_type
  this.start_at = stringToDate(src.start_at)
  this.frequency = src.frequency
  this.interval = src.interval
  this.created_at = new Date(src.created_at)
  this.updated_at = new Date(src.updated_at)  

  if(src.end_at) {
    this.end_at = stringToDate(src.end_at)
  }
  else {
    this.end_at = null
  }
}

export function Cashflow(src) {
  this.id = src.id
  this.name = src.name
  if(src.budget_entries) {
    this.budget_entries = src.budget_entries.map(entry => new CashflowEntry(entry))
  }
  if(src.cashflow_entries) {
    this.cashflow_entries = src.cashflow_entries.map(entry => new CashflowEntry(entry))
  }
  if(src.cashflow_items) {
    this.cashflow_items = src.cashflow_items.map(item => new CashflowItem(item))
  }
  this.last_projected_to = stringToDate(src.last_projected_to)
  this.created_at = new Date(src.created_at)
  this.updated_at = new Date(src.updated_at)
}

function stringToDate(dateString) {
  if(dateString === null) return ''
  var tokens = dateString.split('-')
  
  return new Date(parseInt(tokens[0]), parseInt(tokens[1]) - 1, parseInt(tokens[2]))
}