<template>
  <div class="columns">
    <div class="column is-2">
      <Sidebar/>
    </div>
    <div class="column is-10">
      <slot />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'Default',
  components: {
    Sidebar
  }
}
</script>
