<template>
  <section>
    <h1 class="title">{{category.name}}</h1>

    <div class="field is-grouped">
      <div class="control">
        <b-button label="Edit" tag="router-link" :to="{ name: 'edit_category', params: { id: category.id } }"></b-button>
      </div>

      <div class="control">
        <b-button label="Delete" icon-left="trash" type="is-danger" @click="promptDelete"></b-button>
      </div>        
    </div>
    <hr>

    <div class="columns">
      <div class="column is-quarter">
        <b-field label="Name">
          {{category.name}}
        </b-field>
      </div>

      <div class="column is-quarter">
        <b-field label="Type">
          {{category.category_type}}
        </b-field>
      </div>

      <div class="column is-quarter">
        <b-field label="Color">
          <svg width="30" height="30">
            <rect width="30" height="30" :style="colorSquare()" />
          </svg>
        </b-field>
      </div>

      <div class="column is-quarter">
        <b-field label="Icon">
          <b-icon
            :icon="category.icon"
            size="is-large">
          </b-icon>
        </b-field>
      </div>            
    </div>

    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>  
  </section>
</template>

<script>

export default {
  name: 'ShowCategory',
  props: {
    id: String
  },  

  data () {
    return {
      category: {},
      isLoading: true,
    }
  },
  mounted () {
    this.getCategory()
  }, 
  watch: { 
    id: function(newVal, oldVal) {
      if(newVal === oldVal) {
        return
      }

      this.getCategory()
    }
  },
  methods: {
    getCategory () {
      var url = `/categories/${this.id}`

      this.isLoading = true
      this.$secured.get(url)
        .then(response => {
          this.category = response.data.category
          this.isLoading = false
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        })
    },
    promptDelete () {
      this.$buefy.dialog.confirm({
        title: 'Delete Cashflow',
        message: 'Are you sure you want to <b>delete</b> this category?',
        confirmText: 'Delete Category',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCategory()
      })
    },
    deleteCategory () {
      var url = `/categories/${this.category.id}`

      this.$secured.delete(url)
        .then(response => {
          response
          this.$parent.$emit('close')
          this.$session.removeCategory(this.category)
          this.$router.push({ name: 'home' })

          this.$buefy.toast.open({
            duration: 5000,
            message: `Deleted category`,
            position: 'is-bottom',
            type: 'is-warning'
          })    
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to delete category: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })
    },
    colorSquare () {
      return `fill:${this.category.color};stroke-width:1;stroke:rgb(0,0,0)`
    }    
  }
}
</script>