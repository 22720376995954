<template>
  <div>
    <div class="columns">
      <div class="column is-offset-one-third is-4 box">
        <h5 class="subtitle">Reset Password</h5>
        <hr>
        <form @submit.prevent="reset">
          <b-field label="New Password" :type="fieldType('password')" :message="fieldErrors('password')" >
            <b-input v-model="password" type="password"></b-input>
          </b-field>

          <b-field label="Confirm Password" :type="fieldType('password_confirmation')" :message="fieldErrors('password_confirmation')" >
            <b-input v-model="passwordConfirmation" type="password"></b-input>
          </b-field>       
          <hr>
          <div class="actions">
            <button type="submit" class="button is-primary">Change Password</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  props: ['token'],
  data () {
    return {
      password: '',
      passwordConfirmation: '',
      errors: {}
    }
  },
  methods: {
    reset () {
      this.errors = {}
      this.$session.resetPassword(this.token, this.password, this.passwordConfirmation)
        .then(() => this.$router.replace('/home'))
    },
    fieldErrors (field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType (field) {
      if(this.errors[field] != undefined) {
        return 'is-danger'
      }

      return ''
    },        
  }
}
</script>
