<template>
  <b-menu>
    <b-menu-list label="My Cashflows" v-if="$session.currentUser">
      <b-menu-item tag="router-link" v-for="cashflow in $session.currentUser.cashflows" :key="cashflow.id" :to="{ path: `/cashflows/${cashflow.id}` }" icon="money-bill-wave" :label=cashflow.name></b-menu-item>
      <b-menu-item tag="router-link" label="Add New" :to="{ path: '/cashflows/new' }" icon="plus"></b-menu-item>
    </b-menu-list>
    <b-menu-list label="My Categories" v-if="$session.currentUser">
      <b-menu-item tag="router-link" v-for="category in $session.currentUser.categories" :key="category.id" :to="{ path: `/categories/${category.id}` }" icon="money-bill-wave" :label=category.name></b-menu-item>
      <b-menu-item tag="router-link" label="Add New" :to="{ path: '/categories/new' }" icon="plus"></b-menu-item>
    </b-menu-list>
  </b-menu>
</template>

<script>
export default {
  name: 'Sidebar',
  mounted () {
    this.$session.getProfile()
  },  
  data () {
    return {
    }
  }
}
</script>