<template>
  <section>
    <h1 class="title"><b-icon size="is-small" icon="money-bill-wave"></b-icon>&nbsp;&nbsp;&nbsp;{{cashflow.name}}</h1>
    <b-message
      v-if="emptyCashflow()"
      title="No activity" 
      type="is-info" 
      aria-close-label="Close message">
      This Cashflow looks pretty empty. You may want to get started by adding an actual balance, a one-time entry or a recurring item.
    </b-message>
    
    <b-field grouped>
      <div class="control">
        <b-button label="Add Balance" icon-left="balance-scale" @click="addBalance()"></b-button>
      </div>

      <div class="control">
        <b-button label="Add One-time Entry" icon-left="clock" @click="addCashflowEntry()"></b-button>
      </div>

      <div class="control">
        <b-button label="Add Recurring Item" icon-left="history" @click="addCashflowItem()"></b-button>
      </div>

      <div class="control">
        <b-button label="Delete" icon-left="trash" type="is-danger" @click="promptDelete"></b-button>
      </div>      
    </b-field>

    <b-tabs v-model="activeTab">
      <b-tab-item label="Current Projection">
        <b-field grouped>
          <b-field label="Project To" label-position="on-border" grouped>
            <b-datepicker
              v-model="projectTo"
              placeholder="Project to..."
              icon="calendar-day"
              :min-date="new Date"
              trap-focus>
            </b-datepicker>
          </b-field>

          <b-field label="Find" label-position="on-border" grouped>
            <b-input placeholder="Filter by..."
                  v-model="filter"
                  type="search"
                  icon="search">
              </b-input>
          </b-field>
        </b-field>

        <current-projection :cashflow="cashflow" :id="id" :filter="filter" @refresh="refresh()"></current-projection>
      </b-tab-item>

      <b-tab-item label="Visualization">
        <visualization :cashflow="cashflow" :id="id" @refresh="refresh()"></visualization>
      </b-tab-item>

      <b-tab-item label="Recurring Items">
        <recurring-items :cashflow="cashflow" :id="id" @refresh="refresh()"></recurring-items>
      </b-tab-item>
    </b-tabs>

    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>  
  </section>
</template>

<script>
import utilityMixin from '@/mixins/utilityMixin'
import AddBalanceModal from './AddBalanceModal.vue'
import AddCashflowEntryModal from './AddCashflowEntryModal.vue'
import AddCashflowItemModal from './AddCashflowItemModal.vue'
import CurrentProjection from './CurrentProjection.vue'
import Visualization from './Visualization.vue'
import RecurringItems from './RecurringItems.vue'
import { Cashflow }  from '@/models/models.js'

export default {
  name: 'ShowCashflow',
  mixins: [utilityMixin],
  props: {
    id: String
  },  
  components: {
    'recurring-items': RecurringItems,
    'current-projection': CurrentProjection,
    'visualization': Visualization
  },
  data () {
    return {
      cashflow: {},
      isLoading: true,
      projectTo: null,
      activeTab: 0,
      filter: ''
    }
  },
  mounted () {
    this.getCashflow()
  }, 
  watch: { 
    id: function(newVal, oldVal) {
      newVal
      oldVal
      this.projectTo = null
    },
    projectTo: function(newVal, oldVal) {
      if(this.equalDates(newVal, oldVal)) {
        return
      }

      this.getCashflow()
    }
  },
  methods: {
    mouseEnter (row) {
      this.hovered = row.id
    },
    mouseLeave (row) {
      if(this.hovered == row.id) {
        this.hovered = null
      }
    },        
    refresh () {
      this.getCashflow()
    },
    projectionDate: function() {
      if(this.cashflow && this.cashflow.last_projected_to) {
        return this.cashflow.last_projected_to
      }

      return this.today()
    },
    getCashflow () {
      var url = `/cashflows/${this.id}`
      var params = { project_to: this.projectTo }
      this.isLoading = true

      this.$secured.get(url, { params: params })
        .then(response => {
          console.log('3')

          this.cashflow = new Cashflow(response.data.cashflow)
          this.projectTo = this.projectionDate()

          this.isLoading = false
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        }) 
    },
    emptyCashflow() {
      if(!this.cashflow) return true
      if(!this.cashflow.cashflow_entries) return true

      return this.cashflow.cashflow_entries.length == 0
    },
    balanceStyle (amount) {
      var color = 'green'

      if(amount < 0) {
        color = 'red'
      }
      
      return `color: ${color}`
    },
    addBalance () {
      this.$buefy.modal.open({
        parent: this,
        component: AddBalanceModal,
        hasModalCard: true,
        trapFocus: true,
        props: { cashflow_id: this.id },
        events: { 'refresh': value => { value; this.refresh() } }
      })
    },
    addCashflowEntry () {
      this.$buefy.modal.open({
        parent: this,
        component: AddCashflowEntryModal,
        hasModalCard: true,
        trapFocus: true,
        props: { cashflow_id: this.id },
        events: { 'refresh': value => { value; this.refresh() } }
      })
    },
    addCashflowItem () {
      this.$buefy.modal.open({
        parent: this,
        component: AddCashflowItemModal,
        hasModalCard: true,
        trapFocus: true,
        props: { cashflow_id: this.id },
        events: { 'refresh': value => { value; this.refresh() } }
      })
    },
    promptDelete () {
      this.$buefy.dialog.confirm({
        title: 'Delete Cashflow',
        message: 'Are you sure you want to <b>delete</b> this cashflow?',
        confirmText: 'Delete Cashflow',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteCashflow()
      })
    },
    deleteCashflow () {
      var url = `/cashflows/${this.cashflow.id}`

      this.$secured.delete(url)
        .then(response => {
          response
          this.$parent.$emit('close')
          this.$session.removeCashflow(this.cashflow)
          this.$router.push({ name: 'home' })

          this.$buefy.toast.open({
            duration: 5000,
            message: `Deleted cashflow`,
            position: 'is-bottom',
            type: 'is-warning'
          })    
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: `Failed to delete cashflow: ${error}`,
            position: 'is-bottom',
            type: 'is-danger'
          })    
        })
    },        
  }
}
</script>
