import axios from 'axios'

const securedAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Audience': 'cashflow-app',
    'X-Cashflow-Connection': 'secured'
  }
})

const plainAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,  
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Audience': 'cashflow-app',
    'X-Cashflow-Connection': 'plain'
  }
})

const awsAxiosInstance = axios.create({
  withCredentials: false
})

securedAxiosInstance.interceptors.request.use(config => {
  const method = config.method.toUpperCase()
  if (method !== 'OPTIONS' && method !== 'GET') {
    config.headers = {
      ...config.headers
    }
  }
  return config
})

securedAxiosInstance.interceptors.response.use(null, error => {
  if (error.response && error.response.status === 401) {
    delete localStorage.isAuthenticated
    securedAxiosInstance.router.push('/home')
  }
  
  return Promise.reject(error)   
})

export const BackendPlugin = {
  // eslint-disable-next-line no-unused-vars
  install (Vue, options) {
    if(!options.router) {
      throw new Error("You must supply a router instance in the options.");
    }

    securedAxiosInstance.router = options.router
    Vue.prototype.$secured = securedAxiosInstance,
    Vue.prototype.$plain = plainAxiosInstance,
    Vue.prototype.$aws = awsAxiosInstance
  }
}
