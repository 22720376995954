<template>
  <div>
    <article class="card" style="width: 1200px" >
      <form id="cashflowForm" @submit.prevent="processForm()" class="control">
        <div class="card-content">
          <div class="columns">
            <div class="column is-half">
              <b-field label="Description" :type="fieldType('name')" :message="fieldErrors('name')">
                <b-input v-model="cashflowItem.name"></b-input>
              </b-field>
            </div>

            <div class="column is-half">
              <b-field label="Amount" :type="fieldType('amount')" :message="fieldErrors('amount')">
                <p class="control">
                  <span class="button is-static">$</span>
                </p>
                <b-numberinput v-model="cashflowItem.amount" step=1 controls-alignment="right" controls-rounded controls-position="compact" type="is-light"></b-numberinput>
              </b-field>
            </div>              
          </div>

          <div class="columns">
            <div class="column is-half">
              <b-field label="Item Type" :type="fieldType('item_type')" :message="fieldErrors('item_type')">
                  <b-radio-button v-model="cashflowItem.item_type" name="item_type" native-value="debit">debit</b-radio-button>
                  <b-radio-button v-model="cashflowItem.item_type" name="item_type" native-value="credit">credit</b-radio-button>      
                  <b-radio-button v-model="cashflowItem.item_type" name="item_type" native-value="transfer">transfer</b-radio-button>                      
              </b-field>
            </div>

            <div class="column is-half">
              <b-field label="Target Cashflow" v-if="isTransfer()" :type="fieldType('target_cashflow')" :message="fieldErrors('target_cashflow')">
                <b-select v-model="cashflowItem.target_cashflow_id">
                  <option v-for="otherCashflow in otherCashflows" :value="otherCashflow.id" :key="otherCashflow.id">{{otherCashflow.name}}</option>
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="columns">
            <div class="column is-half">
              <b-field label="Start At" :type="fieldType('start_at')" :message="fieldErrors('start_at')">
                <b-datepicker
                  v-model="cashflowItem.start_at"
                  placeholder="Start at..."
                  icon="calendar-day"
                  :min-date="new Date"
                  trap-focus>
                </b-datepicker>
              </b-field>     

              <b-field label="Interval" :type="fieldType('interval')" :message="fieldErrors('interval')">
                <b-numberinput v-model="cashflowItem.interval" min=1 step=1 controls-alignment="right" controls-rounded controls-position="compact" type="is-light"></b-numberinput>
              </b-field>                               
            </div>
            
            <div class="column is-half">
              <b-field label="End At" :type="fieldType('end_at')" :message="fieldErrors('end_at')">
                <b-datepicker
                  v-model="cashflowItem.end_at"
                  placeholder="End at..."
                  icon="calendar-day"
                  :min-date="new Date"
                  trap-focus>
                </b-datepicker>
              </b-field>   

              <b-field label="Frequency" :type="fieldType('frequency')" :message="fieldErrors('frequency')">
                <b-select v-model="cashflowItem.frequency">
                  <option value='daily'>daily</option>
                  <option value='weekly'>weekly</option>
                  <option value='monthly'>monthly</option>
                  <option value='yearly'>yearly</option>
                </b-select>
              </b-field>              
            </div>         
          </div>
          <span class='columns is-centered'>{{intervalDescription}}</span>
        </div>
        <hr>
        <b-button label="Save" class="is-pulled-right" native-type="submit" type="is-success" />
      </form>
    </article>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { CashflowItem }  from '@/models/models.js'

export default {
  name: 'FormCashflowItem',
  props: {
    id: String,
    cashflow_id: String
  },
  data() {
    return {
      cashflowItem: Object,
      errors: {}
    }
  },
  mounted () {
    if(this.isEdit()) {
      this.getCashflowItem()
    } else {
      this.cashflowItem = {
        interval: 1,
        start_at: new Date,
        end_at: null,
        amount: 0,
        frequency: 'daily',
        item_type: 'debit'
      }
    }
  },
  computed: {
    otherCashflows () {
      return this.$session.currentUser.cashflows.filter(cashflow => this.cashflow_id !== cashflow.id)
    },
    intervalDescription ()  {
      var period = ""
      var interval = ""
      var from = format(this.cashflowItem.start_at, 'yyyy-MM-dd')
      var to = 'indefinitely'

      switch(this.cashflowItem.frequency) {
      case 'daily':
        period = 'day'
        break
      case 'weekly':
        period = 'week'
        break     
      case 'monthly':
        period = 'month'
        break
      case 'yearly':
        period = 'year'
      }

      if(this.cashflowItem.interval > 1) {
        interval = this.cashflowItem.interval
        period = period + 's'
      }
      
      if(this.cashflowItem.end_at) {
        to = 'to ' + format(this.cashflowItem.end_at, 'yyyy-MM-dd')
      }

      return `Repeat every ${interval} ${period} starting from ${from} ${to}`
    }
  },  
  methods: {
    formOperation () {
      return this.isNew() ? 'New' : `Edit`
    },
    isNew () {
      return !this.isEdit()
    },
    isEdit () {
      return this.id !== undefined
    }, 
    isTransfer () {
      return this.cashflowItem.item_type === 'transfer'
    },     
    getCashflowItem () {
      var url = `/cashflows/${this.cashflow_id}/cashflow_items/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.cashflowItem = new CashflowItem(response.data.cashflow_item)
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        }) 
    },   
    processForm() {
      var params = {
        cashflow_item: this.cashflowItem
      }

      if(this.isEdit()) {
        this.updateCashflowItem(params)
      } else {
        this.addCashflowItem(params)
      }
    },
    fieldErrors(field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType(field) {
      if(this.errors[field] !== undefined) {
        return 'is-danger'
      }

      return ''
    },    
    addCashflowItem(params) {
      this.$secured.post(`/cashflows/${this.cashflow_id}/cashflow_items`, params)
      .then((response) => {
        response
        this.$emit('success')
        this.$session.setInfo('Successfully added cashflow item!')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        
        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to add item. Please check any errors and resubmit`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })   
    },
    updateCashflowItem(params) {
      this.$secured.put(`/cashflows/${this.cashflow_id}/cashflow_items/${this.id}`, params)
      .then((response) => {
        response
        this.$emit('success')
        this.$session.setInfo('Successfully updated cashflow item')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        
        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to update item. Please check any errors and resubmit`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })   
    }
  }
}
</script>

