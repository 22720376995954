<template>
  <div>
    <div class="columns">
      <div class="column is-offset-one-third is-4 box">
        <h5 class="subtitle">Sign In</h5>
        <hr>

        <form @submit.prevent="signin">
          <b-field label="Username">
            <b-input type="email" v-model="email" id="email" placeholder="email@example.com" autofocus autocomplete="email" />
          </b-field>

          <b-field label="Password">
            <b-input type="password" v-model="password" id="password" password-reveal />
          </b-field>

          <router-link to="/forgot-password">Forgot Password?</router-link><br/>
          <router-link to="/signup">Create an Account</router-link>
          <hr>
          <div class="actions">
            <button type="submit" class="button is-primary">Log In</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signin',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    signin () {
      this.$session.signin(this.email, this.password)
        .then(() => this.$router.replace('/home'))
    }
  }
}
</script>
