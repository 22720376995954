<template>
  <div>
    <GChart
      type="ColumnChart"
      :options="options"
      :data="chartData()"
    />
  </div>
</template>
 
<script>
import utilityMixin from '@/mixins/utilityMixin'  
import { GChart } from "vue-google-charts"

export default {
  name: "Visualization",
  components: {
    GChart
  },
  mixins: [utilityMixin],
  props: {
    id: String,
    cashflow: {}
  },    
  data() {
    return {
      options: {
        legend: { position: 'bottom' },
        title: this.cashflow.name,
        chartArea: {
          width: '90%',
          height: '80%',
        },
        height: 600
      }  
    }
  },
  methods: {
    chartData () {
      var chartData = []

      chartData.push(["Date", "Running Balance", { role: 'style' }])

      if(this.cashflow && this.cashflow.budget_entries && this.cashflow.last_projected_to !== null) {
        var projectTo = this.cashflow.last_projected_to
        var runningBalance = 0

        for(var date = this.today(); date <= projectTo; date.setDate(date.getDate() + 1)) {
          var entries = this.cashflow.budget_entries.filter(entry => this.equalDates(entry.entry_at, date))

          if(entries.length > 0) {
            runningBalance = entries[entries.length - 1].running_balance
          }

          chartData.push([this.displayDate(date), runningBalance, `color: ${runningBalance < 0 ? 'red' : 'blue'}`])
        }
      }
      return chartData
    }
  }
}
</script>