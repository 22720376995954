<template>
  <div>
    <article class="card" style="width: 1200px" >
      <form id="categoryForm" @submit.prevent="processForm()" class="control">
        <div class="card-content">
          <h1 class="title">{{title()}}</h1>

          <div class="columns">
            <div class="column is-quarter">
              <b-field label="Name" :type="fieldType('name')" :message="fieldErrors('name')">
                <b-input v-model="category.name"></b-input>
              </b-field>
            </div>

            <div class="column is-quarter">
              <b-field label="Category Type" :type="fieldType('category_type')" :message="fieldErrors('category_type')">
                <b-radio-button v-model="category.category_type" name="category_type" native-value="income">income</b-radio-button>
                <b-radio-button v-model="category.category_type" name="category_type" native-value="expenditure">expenditure</b-radio-button>      
              </b-field> 
            </div>

            <div class="column is-quarter">
              <b-field label="Color" :type="fieldType('color')" :message="fieldErrors('color')">
                <color-picker :color="category.color" v-model="category.color" />
              </b-field>
            </div>

            <div class="column is-quarter">
              <b-field label="Icon" :type="fieldType('icon')" :message="fieldErrors('icon')">
                <vue-icon-picker v-model="fullIcon" height="300px"></vue-icon-picker>
              </b-field>
            </div>
          </div>
        </div>

        <footer class="card-footer">
          <p class='card-footer-item'>
            <b-button class='is-success is-fullwidth' native-type="submit">Save</b-button>
          </p>      

          <p class='card-footer-item' v-if="isEdit()">
            <a class='button is-danger is-fullwidth' @click="$router.push({ name: 'show_category', params: { id: category.id } })">Cancel</a>
          </p>                      
        </footer>
      </form>
    </article>
  </div>
</template>

<script>
import VueIconPicker from 'vue-icon-picker'
import ColorPicker from '@/components/ColorPicker'

export default {
  name: 'FormCategory',
  props: {
    id: String
  },
  components: { 
    'vue-icon-picker': VueIconPicker,
    'color-picker': ColorPicker
    },
  data() {
    return {
      category: {},
      errors: {},
      fullIcon: { name: '', color: '#000' , type: 'fontawesome' }
    }
  },
  mounted () {
    if(this.isEdit()) {
      this.getCategory()
    } else {
      this.category = {
        name: null,
        category_type: 'income',
        icon: '',
        color: ''
      }
    }
  },
  methods: {
    title() {
      return this.isNew() ? 'New Category' : `Edit: ${this.name}`
    },    
    formOperation () {
      return this.isNew() ? 'New' : 'Edit'
    },
    isNew() {
      return !this.isEdit()
    },
    isEdit() {
      return this.id !== undefined
    },
    getCategory () {
      var url = `/categories/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.category = response.data.category
          this.fullIcon = { name: response.data.category.icon, color: '#000' , type: 'fontawesome' }
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        }) 
    },   
    processForm() {
      var params = {
        category: this.category
      }

      if(this.fullIcon) {
        params.category.icon = this.fullIcon.name
      }
      if(this.isEdit()) {
        this.updateCategory(params)
      } else {
        this.addCategory(params)
      }
    },
    fieldErrors(field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType(field) {
      if(this.errors[field] !== undefined) {
        return 'is-danger'
      }

      return ''
    },    
    addCategory(params) {
      this.$secured.post('/categories', params)
      .then((response) => {
        this.$session.addCategory(response.data.category)
        this.$router.push({ name: 'show_category', params: { id: response.data.category.id } })
        this.$session.setInfo('Successfully added category!')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        this.$session.setError(`Failed to add category (${error}). Please check any errors and resubmit.`)
      })   
    },
    updateCategory(params) {
      this.$secured.put(`/categories/${this.category.id}`, params)
      .then((response) => {
        response
        this.$router.push({ name: 'show_category', params: { id: this.category.id } })
        this.$session.setInfo('Successfully updated category')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        this.$session.setError(`Failed to update category (${error}). Please check any errors and resubmit.`)
      })   
    }
  }
}
</script>

