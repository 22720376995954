import Vue from 'vue'
import Router from 'vue-router'
import Signin from '@/components/Signin'
import Signup from '@/components/Signup'
import ConfirmAccount from '@/components/ConfirmAccount'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'
import FormCashflow from '@/components/Cashflow/FormCashflow'
import ShowCashflow from '@/components/Cashflow/ShowCashflow'
import FormCategory from '@/components/Category/FormCategory'
import ShowCategory from '@/components/Category/ShowCategory'

Vue.use(Router)

function requireAuth (to, from, next) {
  var authenticated = localStorage.isAuthenticated == 'true'

  if (!authenticated) {
    next({
      path: '/signin',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'signin',
      component: Signin,
      meta: { layout: 'no-sidebar' }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: { layout: 'no-sidebar' }
    },
    {
      path: '/home',
      name: 'home'
    },    
    {
      path: '/cashflows/new',
      name: 'new_cashflow',
      component: FormCashflow,
      beforeEnter: requireAuth
    },  
    {
      path: '/cashflows/edit/:id',
      name: 'edit_cashflow',
      component: FormCashflow,
      beforeEnter: requireAuth,
      props: (route) => ({
        ...route.params
      })
    },       
    {
      path: '/cashflows/:id',
      name: 'show_cashflow',
      component: ShowCashflow,
      beforeEnter: requireAuth,
      props: (route) => ({
        ...route.params
      })
    },  
    {
      path: '/categories/new',
      name: 'new_category',
      component: FormCategory,
      beforeEnter: requireAuth
    },  
    {
      path: '/categories/edit/:id',
      name: 'edit_category',
      component: FormCategory,
      beforeEnter: requireAuth,
      props: (route) => ({
        ...route.params
      })
    },   
    {
      path: '/categories/:id',
      name: 'show_category',
      component: ShowCategory,
      beforeEnter: requireAuth,
      props: (route) => ({
        ...route.params
      })
    },                
    {
      path: '/forgot-password',
      name: 'forgot_password',
      component: ForgotPassword,
      meta: { layout: 'no-sidebar' }
    },
    {
      path: '/reset-password/:token',
      name: 'reset_password',
      component: ResetPassword,
      props: route => ({ token: route.params.token }),
      meta: { layout: 'no-sidebar' }
    },
    {
      path: '/confirm-account/:token',
      name: 'confirm_account',
      component: ConfirmAccount,
      props: route => ({ token: route.params.token }),
      meta: { layout: 'no-sidebar' }
    }    
  ],
  linkActiveClass: 'is-tab is-active'
})
