<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/home' }">
        <img src="@/assets/img/logo.png">
      </b-navbar-item>
    </template>
     
    <template #start>
      <b-navbar-item>
        Cashflow
      </b-navbar-item>
    </template>

    <template #end>
      <div class="navbar-item has-dropdown is-hoverable">
        <div class="navbar-link has-dropdown is-hoverable">
          <a><b-icon pack="fas" icon="user"></b-icon></a>
          <div class="navbar-dropdown">
            <div v-if="$session.currentUser" :key="$session.currentUser.id">
              <a href="#" class="navbar-item" v-if="$session.currentUser">{{$session.currentUser.name}}</a>
              <a href="#" @click.prevent="editProfile()" class="navbar-item" v-if="$session.signedIn()">Edit Profile</a>
              <div class="navbar-divider" v-if="$session.signedIn()"></div>
              <a href="#" @click.prevent="signOut()" class="navbar-item" v-if="$session.signedIn()">Sign out</a>
            </div>
            <router-link to="/" class="navbar-item" v-if="!$session.signedIn()">Sign in</router-link>
          </div>
        </div>
      </div>
    </template>
  </b-navbar>
</template>

<script>
import ProfileModal from './ProfileModal.vue'

export default {
  name: 'Header',
  methods: {
    setError (error, text) {
      this.$session.setError((error.response && error.response.data && error.response.data.error) || text)
    },
    signOut () {
      this.$secured.delete('/logout')
        .then(response => {
          response
          this.$session.clearCurrentUser()
          this.$session.setInfo('Logged out')
          this.$router.replace('/')
        })
        .catch(error => this.$session.setError(error, 'Cannot sign out'))
    },
    editProfile() {
      this.$buefy.modal.open({
        parent: this,
        component: ProfileModal,
        hasModalCard: true,
        trapFocus: true
      })
    }
  }
}
</script>

<style>
  div.navbar-brand {
    background: transparent !important;
    background-color:  green;
  }
</style>
