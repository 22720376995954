<template>
  <div>
    <div class="columns">
      <div class="column is-offset-one-third is-4 box">
        <h5 class="subtitle">Create Account</h5>
        <hr>
        <form @submit.prevent="processForm">
          <b-field label="First Name" :type="fieldType('first_name')" :message="fieldErrors('first_name')">
            <b-input v-model="firstName" autofocus />
          </b-field>

          <b-field label="Last Name" :type="fieldType('last_name')" :message="fieldErrors('last_name')">
            <b-input v-model="lastName" />
          </b-field>        

          <b-field label="Email" :type="fieldType('email')" :message="fieldErrors('email')">
            <b-input type="email" v-model="email" placeholder="address@example.com" autocomplete="email" />
          </b-field>

          <b-field label="Password" :type="fieldType('password')" :message="fieldErrors('password')">
            <b-input type="password" v-model="password" password-reveal/>
          </b-field>

          <hr>
          <div class="actions">
            <b-button native-type="submit" label="Create Account" class="is-primary" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Signup',
  data () {
    return {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      errors: {}
    }
  },
  methods: {
    fieldErrors (field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType (field) {
      if(this.errors[field] != undefined) {
        return 'is-danger'
      }

      return ''
    },        
    processForm () {
      var params = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        password: this.password
      }

      this.errors = {}
      this.$secured.post(`/settings`, params)
      .then(response => {
        response
        this.$router.push({ name: 'home' })
        this.$session.setSuccess('Successfully signed up. Please check your email for confirmation instructions.')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$session.setError('Failed to create account. Please check errors and reattempt.')
      })
    }
  }
}
</script>
