import Vue from 'vue'

let instance

export const getInstance = () => instance

export const session = () => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        loading: true,
        currentUser: null,
        error: '',
        warning: '',
        success: '',
        info: ''
      }
    },
    methods: {
      async signin (email, password) {
       await this.$plain.post('/login', { email: email, password: password })
        .then(response => {
          this.signinSuccessful(response)
        })
        .catch((error) => {
          this.signinFailed(error)
        })
      },
      async forgotPassword (email) {
       await this.$plain.post('/password', { email: email })
        .then(() => this.setInfo('An email will be sent to you with instructions to reset your password.')
        )
        .catch((error) => this.setError(`Unable to initiate password reset (${error})`))
      },   
      async confirmAccount (token) {
       await this.$plain.put('/confirmation', { confirmation_token: token })
        .then(() => this.setInfo('Successfully confirmed your account.')
        )
        .catch((error) => this.setError(`Unable to confirm account (${error})`))
      },         
      async resetPassword (token, password, passwordConfirmation) {
       await this.$plain.put('/password', { reset_password_token: token, password: password, password_confirmation: passwordConfirmation })
        .then(() => this.setInfo('Your password has been updated.')
        )
        .catch((error) => this.setError(`Unable to update your password (${error})`))
      },            
      async getProfile () {
       await this.$secured.get('/settings')
        .then(response => {
          this.setCurrentUser(response.data.user)
        })
        .catch((error) => {
          error
        })
      },
      async getDefaultRoute () {
        var defaultRoute = await this.getProfile()
          .then(ignored => {
            var defaultRoute = { name: 'new_cashflow' }
            ignored

            if(this.signedIn() && this.hasCashflows()) {
              defaultRoute = { name: 'show_cashflow', params: { id: this.currentUser.cashflows[0].id } }
            }
            return defaultRoute
          })

        return defaultRoute
      },
      addCashflow (cashflow) {
        this.currentUser.cashflows.push(cashflow)
      },   
      removeCashflow (cashflow) {
        const index = this.currentUser.cashflows.map(c => c.id).indexOf(cashflow.id)

        if (index > -1) {
          this.currentUser.cashflows.splice(index, 1);
        }
      },   
      addCategory (category) {
        this.currentUser.categories.push(category)
      },   
      removeCategory (category) {
        const index = this.currentUser.categories.map(c => c.id).indexOf(category.id)

        if (index > -1) {
          this.currentUser.categories.splice(index, 1);
        }
      },         
      signinSuccessful (response) {
        if (!response.headers.authorization) {
          this.signinFailed(response)
          return
        }

        this.setCurrentUser(response.data.user)
        this.setInfo('Successfully signed in')
        localStorage.isAuthenticated = 'true'
      },
      signinFailed (error) {
        var errorMsg = error.message

        if(error.response && error.response.data) {
           errorMsg = error.response.data.message
        }

        this.setError(errorMsg)
        this.clearCurrentUser()
        delete localStorage.isAuthenticated
      },      
      setCurrentUser (user) {
        this.currentUser = user
      },
      signedIn () {
        return localStorage.isAuthenticated == 'true'
      },
      hasCashflows () {
        return this.currentUser.cashflows && this.currentUser.cashflows.length > 0
      },
      clearCurrentUser () {
        this.currentUser = null
      },
      clearFlash () {
        this.error = null
        this.warning = null
        this.success = null
        this.info = null
      },
      setError (msg) {
        this.clearFlash()
        this.error = msg
      },
      setWarning (msg) {
        this.clearFlash()
        this.warning = msg
      },
      setSuccess (msg) {
        this.clearFlash()
        this.success = msg
      },      
      setInfo (msg) {
        this.clearFlash()
        this.info = msg
      }
    }
  })

  return instance
}

export const SessionPlugin = {
  install (Vue, options) {
    Vue.prototype.$session = session(options)
  }
}
