<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit Profile</p>
      <button
        type="button"
        class="delete"
        @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <form id="signupForm" @submit.prevent="processForm()" class="control">
        <b-field label="First Name" :type="fieldType('first_name')" :message="fieldErrors('first_name')" >
          <b-input v-model="firstName"></b-input>
        </b-field>

        <b-field label="Last Name" :type="fieldType('last_name')" :message="fieldErrors('last_name')" >
          <b-input v-model="lastName"></b-input>
        </b-field>

        <b-field label="Email" :type="fieldType('email')" :message="fieldErrors('email')" >
          <b-input v-model="email" type="email"></b-input>
        </b-field>

        <b-field help="required" label="Current Password" :type="fieldType('current_password')" :message="fieldErrors('current_password')" >
          <b-input v-model="currentPassword" type="password"></b-input>
        </b-field>

        <hr>

        <b-field label="New Password" :type="fieldType('password')" :message="fieldErrors('password')" >
          <b-input v-model="password" type="password"></b-input>
        </b-field>

        <b-field label="Confirm Password" :type="fieldType('password_confirmation')" :message="fieldErrors('password_confirmation')" >
          <b-input v-model="passwordConfirmation" type="password"></b-input>
        </b-field>        

        <hr>
        <b-button label="Save" class="is-pulled-right" native-type="submit" type="is-success" />
      </form>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ProfileModal',
  data () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
      errors: {}
    }
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    getProfile () {
      this.$secured.get('/settings')
        .then(response => {
          this.firstName = response.data.user.first_name
          this.lastName = response.data.user.last_name
          this.email = response.data.user.email
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        })
    },
    fieldErrors (field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType (field) {
      if(this.errors[field] != undefined) {
        return 'is-danger'
      }

      return ''
    },    
    processForm () {
      var params = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        current_password: this.currentPassword
      }

      if (this.password != '') {
        params.password = this.password
        params.password_confirmation = this.passwordConfirmation
      } 

      this.errors = {}
      this.$secured.put('/settings', params)
      .then(response => {
        this.$session.setCurrentUser(response.data.user)
        this.$emit('close')
        this.$buefy.toast.open({
            duration: 3000,
            message: `Successfully updated profile!`,
            position: 'is-bottom',
            type: 'is-success'
        })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
            duration: 3000,
            message: `Failed to update profile. Please check any errors and resubmit.`,
            position: 'is-bottom',
            type: 'is-danger'
        })
      })
    }
  }
}
</script>
