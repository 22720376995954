<template>
  <form id="cashflowEntryForm" @submit.prevent="processForm()" class="control">
    <div class="columns">
      <div class="column is-half">
        <b-field v-if="!balanceOnly" label="Description" :type="fieldType('name')" :message="fieldErrors('name')">
          <b-input v-model="cashflowEntry.name" autofocus />
        </b-field>
        <b-field label="Entry At" v-else>
          <b-datepicker
            v-model="cashflowEntry.entry_at"
            placeholder="Entry At"
            icon="calendar-day"
            :min-date="new Date"
            trap-focus>
          </b-datepicker>
        </b-field>        
      </div>

      <div class="column is-half">
        <b-field label="Amount" :type="fieldType('amount')" :message="fieldErrors('amount')">
          <p class="control">
            <span class="button is-static">$</span>
          </p>
          <b-numberinput v-model="cashflowEntry.amount" step=1 controls-alignment="right" controls-rounded controls-position="compact" type="is-light"></b-numberinput>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <b-field label="Entry Type" v-if="!balanceOnly" :type="fieldType('entry_type')" :message="fieldErrors('entry_type')">
            <b-radio-button v-model="cashflowEntry.entry_type" name="entry_type" native-value="debit">debit</b-radio-button>
            <b-radio-button v-model="cashflowEntry.entry_type" name="entry_type" native-value="credit">credit</b-radio-button>      
            <b-radio-button v-model="cashflowEntry.entry_type" name="entry_type" native-value="transfer">transfer</b-radio-button>                      
        </b-field>
      </div>

      <div class="column is-half">
        <b-field label="Target Cashflow" v-if="isTransfer()" :type="fieldType('target_cashflow')" :message="fieldErrors('target_cashflow')">
          <b-select v-model="cashflowEntry.target_cashflow_id">
            <option v-for="otherCashflow in otherCashflows" :value="otherCashflow.id" :key="otherCashflow.id">{{otherCashflow.name}}</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="columns">
      <div class="column is-half">
        <b-field label="Entry At" v-if="!balanceOnly">
          <b-datepicker
            v-model="cashflowEntry.entry_at"
            placeholder="Entry At"
            icon="calendar-day"
            :min-date="new Date"
            trap-focus>
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <hr>
    <b-button label="Save" class="is-pulled-right" native-type="submit" type="is-success" />
  </form>
</template>

<script>
import { CashflowEntry }  from '@/models/models.js'

export default {
  name: 'FormCashflowEntry',
  props: {
    id: String,
    cashflow_id: String,
    balanceOnly: Boolean
  },
  data() {
    return {
      cashflowEntry: Object,
      errors: {}
    }
  },
  mounted () {
    if(this.isEdit()) {
      this.getCashflowEntry()
    } else {
      this.cashflowEntry = {
        entry_type: this.balanceOnly ? 'balance' : 'debit',
        entry_at: new Date,
        amount: 0
      }
    }
  },
  computed: {
    otherCashflows () {
      return this.$session.currentUser.cashflows.filter(cashflow => this.cashflow_id !== cashflow.id)
    }
  },
  methods: {
    formOperation () {
      return this.isNew() ? 'New' : `Edit`
    },  
    isNew () {
      return !this.isEdit()
    },
    isEdit () {
      return this.id !== undefined
    },  
    isTransfer () {
      return this.cashflowEntry.entry_type === 'transfer'
    },      
    getCashflowEntry () {
      var url = `/cashflows/${this.cashflow_id}/cashflow_entries/${this.id}`

      this.$secured.get(url)
        .then(response => {
          this.cashflowEntry = new CashflowEntry(response.data.cashflow_entry)
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        }) 
    },   
    processForm() {
      var params = {
        cashflow_entry: this.cashflowEntry
      }

      if(this.isEdit()) {
        this.updateCashflowEntry(params)
      } else {
        this.addCashflowEntry(params)
      }
    },
    fieldErrors(field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType(field) {
      if(this.errors[field] !== undefined) {
        return 'is-danger'
      }

      return ''
    },    
    addCashflowEntry(params) {
      this.$secured.post(`/cashflows/${this.cashflow_id}/cashflow_entries`, params)
      .then((response) => {
        response
        this.$emit('success')
        this.$session.setInfo('Successfully added cashflow entry')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to add entry. Please check any errors and resubmit`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })   
    },
    updateCashflowEntry(params) {
      this.$secured.put(`/cashflows/${this.cashflow_id}/cashflow_entries/${this.id}`, params)
      .then((response) => {
        response
        this.$emit('success')
        this.$session.setInfo('Successfully updated cashflow item')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to update entry. Please check any errors and resubmit`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })   
    }
  }
}
</script>

