import { format } from 'date-fns'

export default {
  methods: {
    formatCurrency (amount) {
      var formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })

      return formatter.format(amount)
    },
    displayDate: function (date) {
      if(date===null) {
        return ''
      }

      var formatTemplate = date.getFullYear() === (new Date).getFullYear() ? 'MMMM d' : 'd-MMM-yyyy'
      var display = format(date, formatTemplate)

      return display
    },
    stringToDate(dateString) {
      var tokens = dateString.split('-')
      
      return new Date(tokens[0], tokens[1], tokens[2])
    },
    today() {
      var todaysDate = new Date
      return new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), 0, 0, 0)
    },
    equalDates(date1, date2) {
      if(date1 === null || date2 === null) {
        return false
      }

      return date1.getTime() == date2.getTime()
    }
  }
}
