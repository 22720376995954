// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import Buefy from 'buefy'
import App from './App'
import router from './router'
import { SessionPlugin } from './session'
import { BackendPlugin} from './backend'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import 'buefy/dist/buefy.css'
 
import Default from './layouts/Default.vue'
import NoSidebar from './layouts/NoSidebar.vue'

Vue.component('default-layout', Default)
Vue.component('no-sidebar-layout', NoSidebar)

library.add(fas)
library.add(fab)

Vue.config.productionTip = false
Vue.use(SessionPlugin, {})
Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas'
})
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(BackendPlugin, { router })

router.beforeEach((to, from, next) => {
  if(to.name === 'home') {
    var authenticated = router.app.$session.signedIn()

    if(authenticated) {
      router.app.$session.getDefaultRoute()
        .then(function(cashflowRoute) {
          next(cashflowRoute)
        })
      } else next({ name: 'signin' })
    } else next()
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
