<template>
  <section>
    <b-table :data="filteredBudgetEntries()" hoverable @mouseenter="mouseEnter" @mouseleave="mouseLeave" :mobile-cards="false">
      <b-table-column field='name' label="Description" v-slot="props">
        <span v-if="canModify(props.row.entry_type) && isHovered(props.row.id)">
          <b-button 
            v-if="canClear(props.row.entry_type, props.row.target_cashflow_id)" 
            label="Clear" 
            icon-left="check" 
            size="is-small" 
            rounded 
            @click="promptClear(props.row.id, props.row.name)"
          >
          </b-button>
          <b-button
            label="Edit" 
            icon-left="edit" 
            type="is-primary" 
            size="is-small" 
            rounded 
            @click="editEntry(props.row.id)"
          >
          </b-button>
          <b-button 
            v-if="canDelete(props.row.entry_type, props.row.target_cashflow_id)" 
            type="is-danger" 
            label="Delete" 
            icon-family="far" 
            icon-left="trash"            
            size="is-small" 
            rounded 
            @click="promptDelete(props.row.id, props.row.name)"
          >
          </b-button>
        </span>    
        <span v-else>{{ props.row.name }}</span>
      </b-table-column>
      <b-table-column v-slot="props" header-class="is-desktop" cell-class="is-desktop">
        <b-icon
          v-if="props.row.recurrence === 'balance'"
          pack="fas"
          icon="balance-scale"
          size="is-small">
        </b-icon>
        <b-icon
          v-if="props.row.recurrence === 'once'"
          pack="fas"
          icon="clock"
          size="is-small">
        </b-icon>
        <b-icon
          v-if="props.row.recurrence === 'recurring'"
          pack="fas"
          icon="history"
          size="is-small">
        </b-icon>        
      </b-table-column>
      <b-table-column field='entry_at' label='Entry Date' v-slot="props">
        {{ displayDate(props.row.entry_at) }}
      </b-table-column>      
      <b-table-column v-slot="props">
        <b-icon
          v-if="props.row.entry_type === 'credit'"
          pack="fas"
          icon="plus"
          size="is-small">
        </b-icon>
        <b-icon
          v-if="props.row.entry_type === 'debit'"
          pack="fas"
          icon="minus"
          size="is-small">
        </b-icon>
        <b-icon
          v-if="props.row.entry_type === 'transfer'"
          pack="fas"
          icon="exchange-alt"
          size="is-small">
        </b-icon>        
      </b-table-column>      
      <b-table-column field='label' label='Credit Amount' numeric  v-slot="props">
        <span v-if='props.row.credit_amount > 0'>{{ formatCurrency(props.row.credit_amount) }}</span>
      </b-table-column>
      <b-table-column field='label' label='Debit Amount' numeric v-slot="props">
        <span v-if='props.row.debit_amount > 0'>{{ formatCurrency(props.row.debit_amount) }}</span>
      </b-table-column>
      <b-table-column field='running_balance' label='Running Balance' numeric v-slot="props">
        <span :style='balanceStyle(props.row.running_balance)'>{{ formatCurrency(props.row.running_balance) }}</span>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import EditCashflowEntryModal from './EditCashflowEntryModal.vue'
import utilityMixin from '@/mixins/utilityMixin'

export default {
  name: 'CurrentProjection',  
  props: {
    id: String,
    cashflow: Object,
    filter: String
  },  
  mixins: [utilityMixin],
  data () {
    return {
      hovered: null
    }
  },
  methods: {
    mouseEnter (row) {
      this.hovered = row.id
    },
    mouseLeave (row) {
      if(this.hovered == row.id) {
        this.hovered = null
      }
    },        
    balanceStyle (amount) {
      var color = 'green'

      if(amount < 0) {
        color = 'red'
      }
      
      return `color: ${color}`
    },
    filteredBudgetEntries () {
      if(this.filter === undefined || this.filter === '') { 
        return this.cashflow.budget_entries
      }

      return this.cashflow.budget_entries.filter(entry => this.filter.length == 0 || entry.name.toLowerCase().includes(this.filter.toLowerCase()))
    },
    promptClear (cashflowEntryId, entryName) {
      this.$buefy.dialog.confirm({
        title: 'Clear Entry',
        message: `Are you sure you want to <b>clear</b> this entry (<i>${entryName}</i>)?`,
        confirmText: 'Clear Entry',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.clearEntry(cashflowEntryId)
      })
    },
    clearEntry (cashflowEntryId) {
      this.$secured.put(`/cashflows/${this.cashflow.id}/cashflow_entries/${cashflowEntryId}/clear`)
      .then(response => {
        response
        this.$emit('refresh')
        this.$buefy.toast.open({
          duration: 1000,
          message: `Cleared`,
          position: 'is-bottom',
          type: 'is-success'
        })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to clear item.`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    },
    promptDelete (cashflowEntryId, entryName) {
      this.$buefy.dialog.confirm({
        title: 'Delete Entry',
        message: `Are you sure you want to <b>delete</b> this entry (<i>${entryName}</i>)?`,
        confirmText: 'Delete Entry',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteEntry(cashflowEntryId)
      })
    },
    deleteEntry (cashflowEntryId) {
      this.$secured.delete(`/cashflows/${this.cashflow.id}/cashflow_entries/${cashflowEntryId}`)
      .then(response => {
        response
        this.$emit('refresh')
        this.$buefy.toast.open({
          duration: 1000,
          message: `Deleted`,
          position: 'is-bottom',
          type: 'is-success'
        })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
          duration: 3000,
          message: `Failed to delete item.`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    },    
    editEntry (cashflowEntryId) {
      this.$buefy.modal.open({
        parent: this,
        component: EditCashflowEntryModal,
        hasModalCard: true,
        trapFocus: true,
        props: { id: cashflowEntryId, cashflow_id: this.id },
        events: { 'refresh': value => { value; this.$emit('refresh') } }
      })      
    },
    canModify (entryType) {
      if(entryType === 'balance') {
        return false
      }

      return true
    },
    canClear (entryType, targetCashflowId) {
      if(entryType === 'transfer' && targetCashflowId === this.cashflow.id) {
        return false
      }

      return true
    },    
    canDelete (entryType, targetCashflowId) {
      if(entryType === 'transfer' && targetCashflowId === this.cashflow.id) {
        return false
      }

      return true
    },        
    isHovered (id) {
      return this.hovered === id   
    }
  }
}
</script>