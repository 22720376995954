<template>
  <div>
    <div class="columns">
      <div class="column is-offset-one-third is-4 box">
        <h5 class="subtitle">Forgot Password</h5>
        <hr>
        <form @submit.prevent="forgot">
          <b-field label="Email" class="field">
            <b-input type="email" v-model="email" placeholder="address@example.com" autofocus autocomplete="email" />
          </b-field>

          <hr>
          <div class="actions">
            <b-button native-type="submit" class="button is-primary">Send Reset Instructions</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    forgot () {
      this.$session.forgotPassword(this.email)
        .then( () => this.$router.replace('/'))
    }
  }
}
</script>
