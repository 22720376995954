<template>
  <div class="modal-card" @success="onSuccess()">
    <header class="modal-card-head">
      <p class="modal-card-title">Edit Cashflow Entry</p>
      <button
        type="button"
        class="delete"
        @click="$emit('close')"/>
    </header>
    <section class="modal-card-body">
      <cashflow-entry-form :cashflow_id="cashflow_id" :id="id" @success="success()"></cashflow-entry-form>
    </section>
  </div>
</template>

<script>
import FormCashflowEntry from './FormCashflowEntry'

export default {
  name: 'EditCashflowEntryModal',
  components: {
    'cashflow-entry-form': FormCashflowEntry
  },
  props: { cashflow_id: String, id: String },
  methods: {
    success () {
      this.$emit('close')
      this.$emit('refresh')
    }
  }
}
</script>

<style>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>  
