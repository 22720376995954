<template>
  <section>
    <b-table :data="cashflow.cashflow_items" hoverable @mouseenter="mouseEnter" @mouseleave="mouseLeave" :mobile-cards="false">
      <b-table-column field='name' label="Description" v-slot="props">
        <span v-if="isHovered(props.row.id)">
          <b-button label="Edit"  type="is-primary" size="is-small" rounded @click="editItem(props.row.id)"></b-button>
          <b-button label="Delete" type="is-danger" size="is-small" rounded @click="confirmDelete(props.row.id)"></b-button>
        </span>    
        <span v-else>{{ props.row.name }}</span>
      </b-table-column>
      <b-table-column field='interval' label='Interval' numeric v-slot="props">
        {{ props.row.interval }}
      </b-table-column>          
      <b-table-column field='frequency' label='Frequency' v-slot="props">
        {{ props.row.frequency }}
      </b-table-column>
      <b-table-column field='interval' label='Item Type' v-slot="props">
        {{ props.row.item_type }}
      </b-table-column>  
      <b-table-column field='label' label='Amount' numeric v-slot="props">
        {{ formatCurrency(props.row.amount) }}
      </b-table-column> 
      <b-table-column field='label' label='Start At'  v-slot="props">
        {{ displayDate(props.row.start_at) }}
      </b-table-column> 
      <b-table-column field='label' label='End At'  v-slot="props">
        {{ displayDate(props.row.end_at)}}
      </b-table-column>         
    </b-table>
  </section>
</template>

<script>
import EditCashflowItemModal from './EditCashflowItemModal.vue'
import utilityMixin from '@/mixins/utilityMixin'

export default {
  name: 'RecurringItems',
  props: {
    id: String,
    cashflow: {}
  },
  mixins: [utilityMixin],
  data () {
    return {
      hovered: null
    }
  },
  methods: {
    mouseEnter (row) {
      this.hovered = row.id
    },
    mouseLeave (row) {
      if(this.hovered == row.id) {
        this.hovered = null
      }
    },        
    isHovered (id) {
      return this.hovered === id   
    },
    confirmDelete (cashflowItemId) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this item?',
        onConfirm: () => this.deleteItem(cashflowItemId)
      })
    },
    editItem (cashflowItemId) {
      this.$buefy.modal.open({
        parent: this,
        component: EditCashflowItemModal,
        hasModalCard: true,
        trapFocus: true,
        props: { id: cashflowItemId, cashflow_id: this.id },
        events: { 'refresh': value => { value; this.$emit('refresh') } }
      })      
    },
    deleteItem (cashflowItemId) {
      this.$secured.delete(`/cashflows/${this.cashflow.id}/cashflow_items/${cashflowItemId}`)
      .then(response => {
        response
        this.$emit('refresh')

        this.$buefy.toast.open({
            duration: 1000,
            message: `Deleted`,
            position: 'is-bottom',
            type: 'is-success'
        })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }

        this.$buefy.toast.open({
            duration: 3000,
            message: `Failed to delete item.`,
            position: 'is-bottom',
            type: 'is-danger'
        })
      })
    }    
  }
}
</script>