<template>
  <div>
    <article class="card" style="width: 1200px" >
      <form id="cashflowForm" @submit.prevent="processForm()" class="control">
        <div class="card-content">
          <h1 class="title">Cashflow</h1>
     
          <b-message
            v-if="!$session.hasCashflows()"
            title="Getting Started" 
            type="is-info" 
            aria-close-label="Close message">
            Welcome to Cashflow!
            <br/>
            <br/>
            To get started you need to create your cashflow by giving it a unique name. It might be the name of a real account or some other descriptor.
          </b-message>

          <div class="columns">
            <div class="column is-third">
              <b-field label="Name" :type="fieldType('name')" :message="fieldErrors('name')">
                <b-input v-model="cashflow.name"></b-input>
              </b-field>
            </div>
          </div>
        </div>

        <footer class="card-footer">
          <p class='card-footer-item'>
            <b-button class='is-success is-fullwidth' native-type="submit">Save</b-button>
          </p>    
          <p class='card-footer-item' v-if="isEdit()">
            <a class='button is-danger is-fullwidth' @click="$router.push({ name: 'show_cashflow', params: { id: cashflow.id } })">Cancel</a>
          </p>            
        </footer>
      </form>
    </article>
  </div>
</template>

<script>
import { Cashflow } from '@/models/models.js'

export default {
  name: 'FormCashflow',
  props: {
    id: String
  },
  data() {
    return {
      cashflow: { },
      errors: {}
    }
  },
  mounted () {
    if(this.isEdit()) {
      this.getCashflow()
    } else {
      this.cashflow = {
        name: null
      }
    }
  },
  methods: {
    formOperation () {
      return this.isNew() ? 'New' : `Edit`
    },
    isNew() {
      return !this.isEdit()
    },
    isEdit() {
      return this.id !== undefined
    },
    getCashflow () {
      var url = `/cashflows/${this.id}`

      this.$http.secured.get(url)
        .then(response => {
          this.cashflow = response.data.cashflow    
        })
        .catch((error) => {
          this.$session.setError(error.msg)
        }) 
    },   
    processForm() {
      var params = {
        cashflow: this.cashflow
      }

      if(this.isEdit()) {
        this.updateCashflow(params)
      } else {
        this.addCashflow(params)
      }
    },
    fieldErrors(field) {
      if(this.errors[field] === undefined) {
        return ''
      }

      return this.errors[field].map(error => error.replace(/^\w/, (c) => c.toUpperCase()))
    },
    fieldType(field) {
      if(this.errors[field] !== undefined) {
        return 'is-danger'
      }

      return ''
    },    
    addCashflow(params) {
      this.$secured.post('/cashflows', params)
      .then((response) => {
        var cashflow = new Cashflow(response.data.cashflow)

        this.$session.setInfo('Successfully added cashflow!')
        this.$session.addCashflow(cashflow)
        this.$router.push({ name: 'show_cashflow', params: { id: response.data.cashflow.id } })
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        this.$session.setError(`Failed to add cashflow (${error}). Please check any errors and resubmit.`)
      })   
    },
    updateCashflow(params) {
      this.$secured.put(`/cashflows/${this.cashflow.id}`, params)
      .then((response) => {
        response
        this.$router.push({ name: 'show_cashflow', params: { id: this.cashflow.id } })
        this.$session.setInfo('Successfully updated cashflow')
      })
      .catch((error) => {
        if (error.response) { 
          this.errors = error.response.data.errors
        }
        this.$session.setError(`Failed to update cashflow (${error}). Please check any errors and resubmit.`)
      })   
    }
  }
}
</script>

