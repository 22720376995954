<template>
  <div>
    <div class="columns">
      <div class="column is-offset-one-third is-4 box">
        <h5 class="subtitle">Confirm Account</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmAccount',
  props: ['token'],
  mounted () {
    this.confirmAccount()
  },  
  methods: {
    confirmAccount () {
      this.$session.confirmAccount(this.token)
        .then( () => this.$router.replace('/'))
    }
  }
}
</script>
