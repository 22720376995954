<template>
  <div id="app">
    <div class="container">
      <Header/>
    </div>
    <section class="section">
      <div class="container">
        <b-notification :key="$session.error" v-if="$session.error" type="is-danger" aria-close-label="Close notification">
          {{$session.error}}
        </b-notification>
        <b-notification :key="$session.warning" v-if="$session.warning" type="is-warning" aria-close-label="Close notification">
          {{$session.warning}}
        </b-notification>
        <b-notification :key="$session.success" v-if="$session.success" type="is-success" aria-close-label="Close notification">
          {{$session.success}}
        </b-notification>        
        <b-notification auto-close :key="$session.info" v-if="$session.info" type="is-info" aria-close-label="Close notification">
          {{$session.info}}
        </b-notification>

        <component :is="layout">
          <router-view/>
        </component>

      </div>
    </section>
  </div>
</template>

<script>
const default_layout = 'default'

import Header from '@/components/Header.vue'

export default {
  name: 'App',
  components: {
    Header
  },
  data () {
    return {
    }
  },
  mounted () {
    console.log('Environment: ' + process.env.NODE_ENV)
    console.log('API URL: ' + process.env.VUE_APP_API_URL)
    console.log('App URL: ' + process.env.VUE_APP_APP_URL)
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout'
    }
  }
}
</script>

<style lang="scss">
$blue: #0f4d81;
$cyan: #00a5cf;
$green: #58ba4f;
$turquoise: #00a5cf;

@import '~bulma/sass/utilities/_all.sass';
@import '~bulma/sass/base/_all.sass';
@import '~bulma/sass/components/navbar.sass';
@import '~bulma/sass/components/card.sass';
@import '~bulma/sass/elements/box.sass';
@import '~bulma/sass/elements/container.sass';
@import '~bulma/sass/elements/icon.sass';
@import '~bulma/sass/elements/image.sass';
@import '~bulma/sass/elements/button.sass';
@import '~bulma/sass/elements/notification.sass';
@import '~bulma/sass/elements/title.sass';
@import '~bulma/sass/elements/other.sass';
@import '~bulma/sass/form/shared.sass';
@import '~bulma/sass/form/input-textarea.sass';
@import '~bulma/sass/form/select.sass';
@import '~bulma/sass/form/tools.sass';
@import '~bulma/sass/grid/columns.sass';
@import '~bulma/sass/grid/tiles.sass';
@import '~bulma/sass/helpers/float.sass';
@import '~bulma/sass/helpers/typography.sass';
@import '~bulma/sass/helpers/visibility.sass';
@import '~bulma/sass/layout/hero.sass';
@import '~bulma/sass/layout/section.sass';

@font-face {
  font-family: 'DINPro-Black';
  src: url(./assets/fonts/DINPro-Black.ttf);
}

@font-face {
  font-family: 'DINPro-Bold';
  src: url(./assets/fonts/DINPro-Medium.ttf);
}

@font-face {
  font-family: 'DINPro-Medium';
  src: url(./assets/fonts/DINPro-Medium.ttf);
}

* {
  font-variant-ligatures: none !important;
}

h1 {
  font-family: "DINPro-Black"
}

h2 { 
  font-family: "DINPro-Medium"
}

p {
  font-family: "DINPro-Medium"
}

label.label {
  font-family: "DINPro-Bold"  
}

input, .select select {
  font-family: "DinPro-Medium"
}

a.navbar-item {
  font-family: "DINPro-Medium"
}

.ii {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: center;
  height: 100%;
  width: 100%;
}

.eye {
  background-image: url('./assets/img/eye.svg');
}

.eye-slash {
  background-image: url('./assets/img/eye-slash.svg');
}

.select,
.select select {
  width: 100%;
}

button.button.is-primary, button.button.is-link, a.button.is-primary, a.button.is-link {
  font-family: "DINPro-Bold";
  background-color: $blue;
  &:disabled {
    background-color: rgba($blue, 0.5);    
  }
  &:hover {
    background-color: rgba($blue, 0.8);
  }
}

a.external-link {
  text-decoration: none !important;
  font-weight: bold;
}
</style>
